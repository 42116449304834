import * as React from 'react';
import './OurDifferentiates.scss';
import ourDifferentiatesData from './defaultData';
import SVG_ICONS_HOMEPAGE from '../../../images/home/svg';

const OurDifferentiates = ({ data = ourDifferentiatesData }) => {
  return (
    <section className="difference">
      <div className="difference__container">
        <div className="difference__content">
          <img src={SVG_ICONS_HOMEPAGE.FIGURES} alt="figures" />
          <div className="difference__textWrapper">
            <h2>What Differentiates Us</h2>
            {data?.map((description, index) => {
              return <p key={index}>{description}</p>;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurDifferentiates;
