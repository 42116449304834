import React, { useState } from 'react';
import './MainHome.scss';
import Technologies from '../Technologies/Technologies';
import { BTN_STYLES, Button } from '../../_Shared/Button/Button';
import Modal from '../../_Shared/Modal/Modal';
import BreadCrumbs from '../../_Shared/Breadcrumbs/Breadcrumbs';

const MainHome = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <>
      <div className="hidden">
        <BreadCrumbs isDark />
      </div>
      <section className="mainHome">
        <div className="mainHome__container">
          <div className="mainHome__content">
            <div className="mainHome__head">
              <h1>Full-Cycle App Development Services</h1>
              <p>
                We’re software development company that provides full cycle development & end-to-end services in web and
                mobile
              </p>
              <Button
                title="Estimate Project"
                className={BTN_STYLES.BTN_PRIMARY_MEDIUM}
                onClick={() => setIsVisible(true)}
              />
            </div>
          </div>
        </div>
        <div className="mainHome__separator" />
        <Technologies />
        <Modal visible={isVisible} onClose={() => setIsVisible(false)} />
      </section>
    </>
  );
};

export default MainHome;
