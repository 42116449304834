import * as React from 'react';
import Main from './MainHome/MainHome';
import { HomeData } from './types';
import Cooperation from './Cooperation/Cooperation';
import Info from './Info/Info';
import WorkSection from './WorkSection/WorkSection';
import Lines from '../_Shared/Lines/Lines';
import BlockWithCard from '../_Shared/BlockWithCard/BlockWithCard';
import OurProcess from '../_Shared/OurProcess/OurProcess';
import Statistics from '../_Shared/Statistics/Statistics';
import OurDifferentiates from './OurDifferentiates/OurDifferentiates';
import AccordionWrapper from '../_Shared/AccordionWrapper/AccordionWrapper';
import Testimonials from '../_Shared/Testimonials/Testimonials';
import Idea from './Idea/Idea';

const HomeContent = ({ data }: HomeData) => {
  const workSectionData = data.allSanityCaseStudy.nodes;
  const blockWithCardData = data.sanityHome.blockWithCardContent;
  const ourProcess = data.sanityHome.ourProcess;
  const statisticsData = data.sanityHome;
  const faqMarkup = data.sanityHome.faqMarkup;

  return (
    <>
      <Main />
      <Cooperation />
      <Info />
      <WorkSection data={workSectionData} />
      <Lines />
      <BlockWithCard data={blockWithCardData} />
      <OurProcess data={ourProcess} />
      <Statistics data={statisticsData} />
      <OurDifferentiates />
      <AccordionWrapper data={faqMarkup} />
      <Testimonials />
      <Idea />
    </>
  );
};

export default HomeContent;
