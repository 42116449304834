import * as React from 'react';
import './Statistics.scss';
import { HomeStatisticsData, StatisticsNewData } from '../../Home/types';

interface StatisticsProps {
  data: HomeStatisticsData;
}

const Statistics = ({ data }: StatisticsProps) => {
  const { statistics } = data;

  return (
    <section className="statistic">
      <div className="statistic__container">
        {statistics.map(({ amount, subtitle }: StatisticsNewData, index: number) => {
          return (
            <div key={index} className="statistic__item">
              <h2>{amount}</h2>
              <p>{subtitle}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Statistics;
