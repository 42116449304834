const ourDifferentiatesData = [
  'We always do our best to give out all the calculations in the shortest possible time',
  'Understanding that the best product is a product that is ready on time and\n' +
    'meets all the requirements of the Client.',
  'Focus on long-term relationships — therefore we treat Client’s projects\n' +
    'as our own, the quality of execution always comes first.',
  "A Team forms a company, not vice versa, that's why soft skills and\n" + 'human qualities are very important for us.',
];

export default ourDifferentiatesData;
