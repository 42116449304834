import React, { useState } from 'react';
import './Idea.scss';
import SVG_ICONS_HOMEPAGE from '../../../images/home/svg';
import { BTN_STYLES, Button } from '../../_Shared/Button/Button';
import Modal from '../../_Shared/Modal/Modal';

interface IdeaProps {
  className?: string;
}

const Idea = ({ className }: IdeaProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <section className={className || 'idea'}>
      <div className="idea__container">
        {className ? null : (
          <div className="idea__textWrapper">
            <h2>Implement your bold business idea with Lunka</h2>
            <p>We always do our best to give out all the calculations in the shortest possible time</p>
          </div>
        )}
        <picture className="idea__img">
          <source
            srcSet={SVG_ICONS_HOMEPAGE.KEYBOARD_LARGE}
            media="(min-width: 1200px)"
            width="1486px"
            height="449px"
          />
          <source
            srcSet={SVG_ICONS_HOMEPAGE.KEYBOARD_MEDIUM}
            media="(min-width: 768px)"
            width="1486px"
            height="336px"
          />
          <img loading="lazy" src={SVG_ICONS_HOMEPAGE.KEYBOARD_SMALL} width="1020px" height="230px" alt="Keyboard" />
        </picture>
        <Button title="Estimate Project" className={BTN_STYLES.BTN_PRIMARY_XLARGE} onClick={() => setIsVisible(true)} />
      </div>
      <Modal visible={isVisible} onClose={() => setIsVisible(false)} />
    </section>
  );
};

export default Idea;
