import React from 'react';
import './Technologies.scss';
import { technologies } from '../../../constants/defaultData';

const Technologies = () => {
  return (
    <div className="mainHome__languages">
      {technologies.map((techName, index) => {
        return <span key={index}>{techName}</span>;
      })}
    </div>
  );
};

export default Technologies;
