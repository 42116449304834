import React, { useState } from 'react';
import './Info.scss';
import { BTN_STYLES, Button } from '../../_Shared/Button/Button';
import Modal from '../../_Shared/Modal/Modal';

const Info = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <>
      <section className="info ">
        <div className="container">
          <div className="info__content">
            <span>Hire full-cycle app development company</span>
            <p>
              Lunka — consulting experts and R&D engineers ready to validate, turn into something feasible and implement
              your bold business ideas with help of innovative technology — find out which solution works best for your
              business goals.
            </p>
            <Button
              title="Estimate Project"
              onClick={() => setIsVisible(true)}
              className={BTN_STYLES.BTN_PRIMARY_LARGE}
            />
          </div>
        </div>
      </section>
      <Modal visible={isVisible} onClose={() => setIsVisible(false)} />
    </>
  );
};

export default Info;
