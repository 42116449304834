import * as React from 'react';
import PageLayout from '../components/_Shared/PageLayout/PageLayout';
import HomeContent from '../components/Home/index';
import 'normalize.css';
import '../globalStyles/_fonts.scss';
import '../globalStyles/_styles.scss';
import { graphql } from 'gatsby';
import { homeTitle } from '../seoData/titles';
import { HomeData } from '../components/Home/types';

export const query = graphql`
  query MyHomePageQuery {
    sanityHome {
      statistics {
        amount
        subtitle
      }
      blockWithCardContent {
        cardTitle
        cardIcon {
          asset {
            url
          }
        }
      }
      faqMarkup {
        question
        _rawAnswers
      }
      ourProcess {
        _rawContent
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityCaseStudy(
      filter: {
        id: {
          in: [
            "-90fdab4f-5af2-564b-bca2-696614fd486a"
            "-aed86b7b-8c1c-5057-84ab-f2c54775344d"
            "-9ae1a46e-7634-52b4-8955-1ded81fa72e9"
          ]
        }
      }
    ) {
      nodes {
        title
        cardTitle
        slug {
          current
        }
        image {
          asset {
            url
          }
        }
        workDescription
      }
    }
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
  }
`;

const IndexPage = ({ data }: HomeData) => {
  const { allSanityFooter } = data;
  const microMarkup = data.sanityHome.faqMarkup;

  return (
    <PageLayout footerData={allSanityFooter} title={homeTitle} markData={microMarkup}>
      <HomeContent data={data} />
    </PageLayout>
  );
};

export default IndexPage;
